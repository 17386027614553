import React from "react";
import styled from "styled-components";
import { useTheme } from "./ThemeProvider";
import { Text } from "../components/typography";
import { fontKey } from "../theme/fonts";
import { Theme } from "../theme/theme";
import { Link } from "gatsby";

const LinkInternalBase = styled(Link)`
  outline-color: ${(props) => props.theme.semanticColors.bodyText};
  color: ${(props) => props.theme.semanticColors.linkTextHot};
  display: inline-block;
`;

function LinkInternal(props) {
  const theme = useTheme();
  return (
    <LinkInternalBase theme={theme} to={props.to} {...props}>
      {props.children}
    </LinkInternalBase>
  );
}

const LinkStandardBase = styled.a((props: { theme: Theme }) => ({
  color: props.theme.semanticColors.linkTextHot,
  padding: "0px 3px",
  "> *": {
    color: `${props.theme.semanticColors.linkTextHot} !important`,
  },
  ":focus": {
    outlineColor: props.theme.semanticColors.bodyText,
  },
}));

function LinkStandard(props: {
  href: string;
  children: React.ReactChildren | React.ReactChild;
  variant?: fontKey;
}) {
  const theme = useTheme();
  const { href } = props;
  return (
    <LinkStandardBase href={href} theme={theme}>
      <Text variant={props.variant || "medium_D"}>{props.children}</Text>
    </LinkStandardBase>
  );
}

export { LinkStandard, LinkInternal };
