import React from "react";
import Styled from "styled-components";
import { Theme } from "../theme/theme";
import { useTheme } from "./ThemeProvider";
import { Text } from "./typography";

const StyledLiBase = Styled.li((props: { theme: Theme }) => ({
  color: props.theme.semanticColors.bodyText,
}));

function StyledLi(props) {
  const theme = useTheme();
  return (
    <StyledLiBase {...props} theme={theme}>
      <Text variant="medium_C">{props.children}</Text>
    </StyledLiBase>
  );
}

export { StyledLi };
