/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import { StaticQuery, graphql } from "gatsby";
// import Image from "gatsby-image";
import styled from "styled-components";
import { Text } from "../components/typography";
import { LinkStandard } from "../components/link";

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { author, social } = data.site.siteMetadata;
        return (
          <Container>
            <Text variant="small_C" style={{ display: "inline" }}>
              Written by {author}, a front end developer living in Seattle.
              {` `}
              <LinkStandard
                style={{ display: "inline" }}
                variant="small_C"
                href={`https://twitter.com/${social.twitter}`}
              >
                Follow me on Twitter
              </LinkStandard>
            </Text>
          </Container>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/gatsby-icon.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
`;

export default Bio;
