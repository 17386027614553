import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import Bio from "../components/bio";
import SEO from "../components/seo";
import { Text } from "../components/typography";
import Layout from "../components/layout";
import { CodeBlock } from "../components/codeBlock";
import { StyledLi } from "../components/listItem";
import styled from "styled-components";
import { useTheme } from "../components/ThemeProvider";
import { LinkInternal, LinkStandard } from "../components/link";
import "gitalk/dist/gitalk.css";
import GitalkComponent from "gitalk/dist/gitalk-component";
import PropsRender from "../components/propsRender";
import { Md5 } from "md5-typescript";

const shortCodes = { LinkStandard, LinkInternal };

const HrS = styled.hr({
  marginTop: 50,
});

const StyledUl = styled.ul({
  maxWidth: 580,
  margin: "auto",
  padding: "20px 30px",
});

const NextPostContainer = styled.div((props) => ({
  padding: 10,
  maxWidth: 200,
  "&:hover": {
    boxShadow: `-5px 5px 0px 5px ${props.theme.semanticColors.cardHover}`,
  },
}));

function BlogPostTemplate(props) {
  const post = props.data.mdx;
  const { previous, next } = props.pageContext;
  const theme = useTheme();

  return (
    <Layout {...props}>
      <SEO title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt || "no description"} />
      <Text variant={"large_D"}>{post.frontmatter.title}</Text>
      <Text
        variant="small_B"
        style={{
          display: `block`,
        }}
      >
        {post.frontmatter.date}
      </Text>
      <MDXProvider
        components={{
          ...shortCodes,
          p: (props) => (
            <Text variant={"medium_C"} style={{ margin: "20px 0px" }}>
              {props.children}
            </Text>
          ),
          a: (props) => <LinkStandard {...props}>{props.children}</LinkStandard>,
          h2: (props) => (
            <Text variant={"large_D"} {...props}>
              {props.children}
            </Text>
          ),
          h3: (props) => (
            <Text variant={"large_C"} {...props}>
              {props.children}
            </Text>
          ),
          h4: (props) => (
            <Text variant={"large_B"} {...props}>
              {props.children}
            </Text>
          ),
          li: (props) => <StyledLi>{props.children}</StyledLi>,
          ul: (props) => <StyledUl>{props.children}</StyledUl>,
          pre: CodeBlock,
        }}
      >
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>
      <HrS />
      <Bio />
      {/* <PropsRender {...props} /> */}
      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        {previous?.node ? (
          <LinkInternal to={`/blog${previous.node.fields.slug}`} rel="prev">
            <NextPostContainer theme={theme}>
              <StyledLi>
                <Text variant="small_D">← {previous.node.frontmatter.title}</Text>
              </StyledLi>
            </NextPostContainer>
          </LinkInternal>
        ) : (
          // rendering empty div to provide spacing for flex layout when there is only one element
          <div></div>
        )}
        {next?.node ? (
          <LinkInternal to={`/blog${next.node.fields.slug}`} rel="next">
            <NextPostContainer theme={theme}>
              <StyledLi>
                <Text variant="small_D">{next.node.frontmatter.title} →</Text>
              </StyledLi>
            </NextPostContainer>
          </LinkInternal>
        ) : (
          // rendering empty div to provide spacing for flex layout when there is only one element
          <div></div>
        )}
      </ul>
      {typeof window !== "undefined" && (
        <GitalkComponent
          options={{
            clientID: "22fd23be3e0bd7d71142",
            clientSecret: "fa1484f97c1f85cad35371af05a6e6135fd7f147",
            repo: "hellodeary-blog",
            owner: "mikeybkats",
            admin: ["mikeybkats"],
            id: Md5.init(props.location.href),
          }}
        />
      )}
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        mode
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
